import '../css/index.scss';
import Modal from '../components/modal'
import '../assets/favicon.ico'
import { InteractionLoader } from '../components/interactionLoader';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

new (class Index {
    constructor() {
        this.container = document.body
        this.actions()

        new InteractionLoader({
            'analytics': {
                'url': 'https://www.googletagmanager.com/gtag/js?id=G-YC7EQQK7GC',
                'onload': () => {
                    window.dataLayer = window.dataLayer || [];
                    window.gtag = () => { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', 'G-YC7EQQK7GC');
                    gtag('event', 'page_view', {
                        page_title: document.title,
                        page_location: document.location.href
                    });
                }
            },
            'fonts': {
                'url': 'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap',
                'type': 'font'
            }
        })
    }

    actions() {
        document.querySelector('[data-modal=signup]').onclick = () => {
            this.modal = new Modal(`
                <div class="form">
                    <input class="f-name" type="text" placeholder="Your name" name="name" />
                    <input class="f-mail" type="email" placeholder="Your email" name="email" />
                    <input class="f-size" type="text" placeholder="Business size (ex: 60)" name="size" />
                    <button class="modal-submit" type="submit">Send</button>
                </form>
            `)
            this.modal.container.querySelector('.modal-submit').onclick = () => {
                fetch('/.netlify/functions/submit', {
                    method: 'POST',
                    body: JSON.stringify({
                        name: this.modal.container.querySelector('.f-name').value,
                        mail: this.modal.container.querySelector('.f-mail').value,
                        size: this.modal.container.querySelector('.f-size').value,
                        type: 'form'
                    })
                }).then(r => r.json()).then(res => {
                    this.modal.hide()
                    console.log(res)
                })
            }
        }

        this.swiper = new Swiper(".mySwiper", {
            speed: 600,
            parallax: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            modules: [Navigation, Pagination],
        });
    }
})()